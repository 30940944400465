import Footer from './footer';
import Nosotros from './nosotros';
import Mision from './mision';
import Vision from './vision';

export default function MyV() {
    return (
        <div className="myv">
            <Nosotros/>
            <div className='prueba-myv'></div>
            <Mision/>
            <div className='prueba-myv'></div>
            <Vision/>
            <div className='prueba-myv2'></div>
        </div>

    )
}

import vision from './media/vision.png';

export default function Vision() {
    return ( 
        <div className="vision-portrait">
            <div className='title-vision'>
                <h3>Visión</h3>
                <p>Todos los miembros de Blair compartimos una visión común que buscamos hacer realidad día con día:</p>
                <imgss src={vision} className="vis-img" width={50} height={50} alt="..." />
                <p>Ser una de las compañías de alimentos más importantes a nivel mundial reconocida no solo por la calidad de sus productos sino por su compromiso social y ambiental</p>
            </div>
        </div>
    )
}
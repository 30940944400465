import Footer from './footer';

export default function Contacto() {
    return (
        <div>
            <div className="contacto">
                <div className="title">
                    <div>
                        <h2>Contacto</h2>
                        <h3>Para cualquier pregunta o comentario no dudes en contactarnos. Estamos muy interesados en ayudarte.</h3>
                    </div>
                </div>
                <div className="datos-contacto">
                    <div>
                        <h6>Proveedores</h6>
                        <p>proveedores@blairfoods.com</p>
                    </div>
                    <div>
                        <h6>Pedidos</h6>
                        <p>pedidos@blairfoods.com</p>
                    </div>
                    <div>
                        <h6>Atencion a clientes</h6>
                        <p>clientes@blairfoods.com</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
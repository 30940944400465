
import Slidebar from "./slidebar";
import Cart from './carrito';
import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logo from './media/logo-black.jpg';
import scart from './media/shop_cart.png';
import person from './media/person-orange.png';
import { CartContext } from "./CartContext";
//import AuthContext from "./AuthContext";
import Search from './search';

export default function Mainbar() {

    const [showSlidebar, setShowSlidebar] = useState(false)
    const [showCart, setShowCart] = useState(false)

    const { cart } = useContext(CartContext)

    //const { user, logoutUser } = useContext(AuthContext)

    //const [cliente, setCliente] = useState("Iniciar sesion")

   
    // useEffect(()=>{
    //     if (user) {
    //         setCliente("Cerrar sesion")
    //     } else {
    //         setCliente("Iniciar sesion")
    //     }
    // },[user])
    

    let carro = cart;
    let i = carro.length

    function closeSlideBar() {
        setShowSlidebar(false)
      }

    function closeCart() {
        setShowCart(false)
      }

      const Ref = useRef(null);
    
      const handleOutsideClick = (e) => {
        if (
          Ref.current &&
          !Ref.current.contains(e.target) 
        ) {
          closeCart()
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
        };
      }, []);

    return (
        <div className="mainbar"  ref={Ref}>
            <div className="mainbar-container">
                <Link to="/" className="logo"><img src={logo} width={200} height={80} alt="..."/></Link>
                <Link to="/" className="blairfoods"><div >BLAIRFOODS</div></Link>
                <Search/>
                <div className="mainbar-div">
                    <div onClick={() => setShowCart(!showCart)} className= {showCart ? "cart-btn active" : "cart-btn"}>
                        <div className="cart-spoiler"><img src={scart} className="carrito-img" width={40} height={40} alt="..."/><p>Carrito</p></div>
                        <div className='item-amount-bar'>{i}</div>
                    </div>
                    <Link to="/login" className="mainbar-link" id="cliente" ><img src={person} className="cuenta-img" width={30} height={30} alt="..."/>Iniciar sesion</Link>
                    <div onClick={() => setShowSlidebar(!showSlidebar)} className= {showSlidebar ? "slide-btn active" : "slide-btn"}>
                        <div className= {showSlidebar ? "toggle active" : "toggle"}>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
            {showSlidebar && <Slidebar close={closeSlideBar}/>}
            {showCart && <Cart close={closeCart}/>}
        </div>
    )
}

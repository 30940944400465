import Footer from './footer';
import React, { useState, useContext, useEffect } from "react";


export default function Clientes() {

    const [nombre, setNombre] = useState()
    const [email, setEmail] = useState()
    const [comentario, setComentario] = useState()

    const cliente = {
        'nombre': nombre,
        'email': email,
        'comentario': comentario
    }

    const submitCliente = () => {
        fetch('/blairfoodsb/clientes/create/', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(cliente),
        })
        alert("Su mensaje ha sido enviado!")
    }


    return (
        <div>
            <div className="clientes">
                <div className="title">
                    <div>
                        <h2>Clientes</h2>
                        <h5>Blair es una compañía 100% centrada en el cliente que busca mejorar continuamente para satisfacer al máximo las necesidades y expectativas de nuestros consumidores. Los comentarios de nuestros clientes son la guía que direcciona nuestros esfuerzos para mejorar y seguir desarrollando nuestro catálogo de productos</h5>
                    </div>
                </div>
                <div className="c-info">
                    <div className="texto">
                        <h5>Queremos escucharte</h5>
                        <p>Danos tu opinión sobre nuestros productos y servicio a través del siguiente formulario, o si lo prefieres, envíanos un correo a clientes@blairfoods.com.

                        También puedes llamarnos a nuestra línea de atención a clientes al 442 9802273 opción 3.

                        Ya sea que no hayas encontrado el producto que buscas o consideres que nuestro servicio tiene algún área de oportunidad, háznoslo saber, estamos muy interesados en escucharte y poder mejorar para ti.</p>
                    </div>
                    <div className="datos-cliente">
                        <form onSubmit={submitCliente}>
                            <div className="form-wrapper">
                                <input type="text" placeholder="Nombre" onChange={(e) => {setNombre(e.target.value)}}/>
                                <input type="text" placeholder="Correo electronico" onChange={(e) => {setEmail(e.target.value)}}/>
                            </div>
                            <textarea className="cli-input" type="text" placeholder="Comentarios" onChange={(e) => {setComentario(e.target.value)}}/>
                            <br/>
                            <input className="submit" type="submit"value="Enviar"/>
                        </form>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
import Footer from './footer';
import React, { useState, useContext, useEffect } from "react";


export default function Proveedores() {

    const [compañia, setCompañia] = useState()
    const [nombre, setNombre] = useState()
    const [telefono, setTelefono] = useState()
    const [email, setEmail] = useState()
    const [suministro, setSuministro] = useState()
    const [certificaciones, setCertificaciones] = useState()

    const proveedor = {
        'compañia': compañia,
        'nombre': nombre,
        'telefono': telefono,
        'email': email,
        'suministro': suministro,
        'certificaciones': certificaciones
    }

    const submitProveedor = () => {
        fetch('/blairfoodsb/proveedores/create/', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(proveedor),
        })
        alert("Su solicitud ha sido enviada!")
    }


    return (
        <div>
            <div className="proveedores">
                <div className="title">
                    <div>
                        <h2>Proveedores</h2>
                        <h5>Para Blair, los proveedores son integrantes clave del equipo con los cuales queremos relaciones ganar-ganar y de largo plazo. El éxito de nuestros proveedores es el éxito de Blair.</h5>
                    </div>  
                </div>
                <div className="p-info">
                    <div className="texto">
                        <h5>Permítenos conocerte</h5>
                        <p>Si te interesa formar parte de nuestro equipo de proveedores, por favor envíanos tu solicitud a través del siguiente formulario, o si lo prefieres, envíanos un correo a proveedores@blairfoods.com.

                        También puedes llamarnos a nuestra línea de atención a proveedores al 442 9802273 opción 2.

                        No lo dudes, no importa en donde te encuentres, ni que tan grande seas, permítenos conocerte.</p>
                        <h5>Confirmaremos tu solicitud</h5>
                        <p>Una vez enviada tu solicitud, te confirmaremos la recepción de esta y posteriormente nos pondremos en contacto para agendar una llamada y platicar sobre las oportunidades de colaboración.</p>
                    </div>
                    <div className="datos-proveedor">
                        <form onSubmit={submitProveedor}>
                            <div className="form-wrapper">
                                <input type="text" placeholder="Compañia" onChange={(e) => {setCompañia(e.target.value)}}/>
                                <input type="text" placeholder="Nombre de contacto" onChange={(e) => {setNombre(e.target.value)}}/>
                                <input type="text" placeholder="Telefono de contacto" onChange={(e) => {setTelefono(e.target.value)}}/>
                                <input type="text" placeholder="Correo electronico" onChange={(e) => {setEmail(e.target.value)}}/>
                            </div>
                            <textarea className="prov-input" type="text" placeholder="Producto o servicio que suministra" resize="none" onChange={(e) => {setSuministro(e.target.value)}}/>
                            <br/>
                            <textarea className="prov-input" type="text" placeholder="Certificaciones (si aplica)" onChange={(e) => {setCertificaciones(e.target.value)}}/>
                            <br/>
                            <input className="submit" type="submit" value="Enviar"/>
                        </form>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
import React, { useContext, useEffect } from "react";
import { ProductContext } from './ProductContext';
import Product from './product';


export default function HotPics() {

    const { products } = useContext(ProductContext)

    const pic1 = products.filter((product)=> product.id === 55 )
    const pic2 = products.filter((product)=> product.id === 41 )
    const pic3 = products.filter((product)=> product.id === 88 )
    const pic4 = products.filter((product)=> product.id === 48 )
    const pic5 = products.filter((product)=> product.id === 38 )
    const pic6 = products.filter((product)=> product.id === 90 )
    const pic7 = products.filter((product)=> product.id === 62 )
    const pic8 = products.filter((product)=> product.id === 96 )
    const pic9 = products.filter((product)=> product.id === 64 )
    const pic10 = products.filter((product)=> product.id === 66 )
    const pic11 = products.filter((product)=> product.id === 61)
    const pic12 = products.filter((product)=> product.id === 74 )
    const pic13 = products.filter((product)=> product.id === 53 )
    const pic14 = products.filter((product)=> product.id === 44 )
    const pic15 = products.filter((product)=> product.id === 63 )
    


    return (
        <div className="hotpics">
            <h1 className="hotpics-title">Productos populares</h1>
            <div className="pics-container">
                {pic1.map((product) => {return (<Product product={product} key={product.id}/>)})} 
                {pic2.map((product) => {return (<Product product={product} key={product.id}/>)})} 
                {pic3.map((product) => {return (<Product product={product} key={product.id}/>)})} 
                {pic4.map((product) => {return (<Product product={product} key={product.id}/>)})} 
                {pic5.map((product) => {return (<Product product={product} key={product.id}/>)})} 
                {pic6.map((product) => {return (<Product product={product} key={product.id}/>)})} 
                {pic7.map((product) => {return (<Product product={product} key={product.id}/>)})} 
                {pic8.map((product) => {return (<Product product={product} key={product.id}/>)})} 
                {pic9.map((product) => {return (<Product product={product} key={product.id}/>)})} 
                {pic10.map((product) => {return (<Product product={product} key={product.id}/>)})} 
                {pic11.map((product) => {return (<Product product={product} key={product.id}/>)})} 
                {pic12.map((product) => {return (<Product product={product} key={product.id}/>)})} 
                {pic13.map((product) => {return (<Product product={product} key={product.id}/>)})} 
                {pic14.map((product) => {return (<Product product={product} key={product.id}/>)})} 
                {pic15.map((product) => {return (<Product product={product} key={product.id}/>)})} 
    
            </div>
            
        </div>
    )
}
import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import person from './media/person.svg';
import AuthContext from "./AuthContext";
import Slidebar2 from "./slidebar2";


export default function Slidebar({close}) {

    //const { user, logoutUser } = useContext(AuthContext)

    const [cliente, setCliente] = useState("Iniciar sesion")

   
    // useEffect(()=>{
    //     if (user) {
    //         setCliente("Cerrar sesion")
    //     } else {
    //         setCliente("Iniciar sesion")
    //     }
    // },[user])

    const [showCat, setShowCat] = useState(false)

    return (
        <div className="slide-menu">
            <Link to="/" className="s-link" onClick={close}>Inicio</Link>
            {/* <Link to="/login" className="s-link" onClick={cliente ? logoutUser : ''}><img src={person} className="cuenta-img" width={30} height={30} alt="..."/>{cliente}</Link> */}
            <Link to="/login" className="s-link" onClick={close}><img src={person} className="cuenta-img" width={30} height={30} alt="..."/>{cliente}</Link>
            <div className="s-link">
                <div onClick={() => setShowCat(!showCat)}>Categorias</div>
                <div className="slide-menu2" onClick={close}>{showCat && <Slidebar2/>}</div>  
            </div>           
            <Link to="/myv" className="s-link" onClick={close}>Mision y vision</Link>
            <Link to="/principios" className="s-link" onClick={close}>Principios</Link>
            <Link to="/proveedores" className="s-link" onClick={close}>Proveedores</Link>
            <Link to="/clientes" className="s-link" onClick={close}>Clientes</Link>
            <Link to="/contacto" className="s-link" onClick={close}>Contacto</Link>
        </div>
    )
}

import './App.css';
import { Routes, Route, HashRouter } from 'react-router-dom';
import Login from './components/login';
import Mainbar from './components/mainbar';
import Navbar from './components/navbar';
import Hero from './components/hero';
import Contacto from './components/contacto';
import MyV from './components/myv';
import Cart from './components/carrito';
import Cereales from './components/cereales';
import Chiles from './components/chiles';
import ProductDetails from './components/detail';
import Especias from './components/especias';
import Frutas from './components/frutas';
import Informacion from './components/info'
import Principios from './components/principios';
import Proveedores from './components/proveedores';
import Clientes from './components/clientes';
import Semillas from './components/semillas';
import Verduras from './components/verduras';
import Slidebar2 from './components/slidebar2';
// import Footer from './components/footer';

function App() {

  return (
    <div className="App" >
      <HashRouter>
        <Mainbar/>
        <Navbar/>
          <div className='main'>
            <Routes>
              <Route path="/" element={<div><Hero/></div>}/>
              <Route path="/login" element={<div><Login/></div>}/> 
              <Route path="/informacion" element={<div><Informacion/></div>}/> 
              <Route path="/categorias" element={<div><Slidebar2/></div>}/> 
              <Route path="/myv" element={<div><MyV/></div>}/>
              <Route path="/principios" element={<div><Principios/></div>}/>
              <Route path="/contacto" element={<div><Contacto/></div>}/>
              <Route path="/proveedores" element={<div><Proveedores/></div>}/>
              <Route path="/clientes" element={<div><Clientes/></div>}/> 
              <Route exact path={'/detail/:id'} element={<div><ProductDetails/></div>}/> 
              <Route path="/carrito" element={<div><Cart/></div>}/> 
              <Route path="/cerealesyleguminosas" element={<div><Cereales/></div>}/> 
              <Route path="/chilessecos" element={<div><Chiles/></div>}/> 
              <Route path="/frutossecosysemillas" element={<div><Semillas/></div>}/> 
              <Route path="/especias" element={<div><Especias/></div>}/> 
              <Route path="/frutas" element={<div><Frutas/></div>}/> 
              <Route path="/verduras" element={<div><Verduras/></div>}/> 
            </Routes>
          </div>
      </HashRouter>
    </div>
  );
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom';
import cartwhite from './media/cartwhite.png';


const Product = ({product}) => {
    
    const { id, imagen, nombre, precioMenudeo, unidadMedicion } = product
 
    return (
        <div className='product'>
            <div className='img-product'><img className="image" src={imagen} alt="" /></div>  
            <div className='info-container'>
                <div className='name-product'>{nombre}</div>
                <div className='añadir'>
                    <div className='price-product'>
                        <div className='precio-producto'> ${precioMenudeo}</div>
                        <div className='unidad-medicion'>{unidadMedicion}</div>
                    </div>
                    <Link className='añadir-btn' to={`/detail/${id}`}><img width={20} height={20} src={cartwhite}/><p>Comprar</p></Link>
                </div>
            </div>
        </div> 
    )   
}
  
  export default Product;



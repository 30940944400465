
import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ProductContext } from './ProductContext';
import { CartContext } from './CartContext';
import up from './media/chevron-up.svg';
import down from './media/chevron-down.svg';
import Footer from './footer';
import cartwhite from './media/cartwhite.png';


const ProductDetails = () => {

    const navigate = useNavigate();
   
    const { id } = useParams()
    const { products } = useContext(ProductContext)
    const { addToCartD, cart } = useContext(CartContext)

    const product = products.find((item) => {
        return item.id === parseInt(id)
    })

    const itemObj = Object.assign({}, product)

    const name = itemObj.nombre
    const imagen = itemObj.imagen
    const categoria = itemObj.categoria
    const precioU = itemObj.precioUnitario
    const precioMe = itemObj.precioMenudeo
    const precioMa = itemObj.precioMayoreo
    const uvmayoreo = itemObj.uvMayoreo
    const quantity = itemObj.unidadMedicion



    const [catName, setCatName] = useState()

    const fijarCatName = () => {
        if( categoria === 1) {
            setCatName("Frutas")
        }
        if( categoria === 2) {
            setCatName("Verduras")
        }
        if( categoria === 3) {
            setCatName("Chiles secos")
        }
        if( categoria === 4) {
            setCatName("Frutos secos y semillas")
        }
        if( categoria === 5) {
            setCatName("Especias")
        }
        if( categoria === 6) {
            setCatName("Cereales y leguminosas")
        }
    }
    
    useEffect(()=>{
        fijarCatName()
    },[categoria])


    const [cantidad, setCantidad] = useState(1)
    
    const sumar = (id) => {
        var numero = cantidad
        var sumar = ++numero 
        setCantidad(sumar, id)
    }

    const restar = (id) => {
        if(cantidad > 1) {
            var numero = cantidad
            var restar = --numero 
            setCantidad(restar, id)
        }
        else {}
    }

    const showParamF = () => {
        const elementP = document.getElementById("pieza");
        const elementM = document.getElementById("maduracion");
        if (elementP) {
            elementP.remove();
        }
        if (elementM) {
            elementM.remove();
        }
    }
    const showParam = () => {
        if (categoria === 4) {
            showParamF()
        }
        if (categoria === 5) {
            showParamF()
        }
        if (categoria === 6) {
            showParamF()
        }
    }

    useEffect(()=>{
        showParam()
    },[categoria])
        
    const [precio, setPrecio] = useState(precioMe)

    useEffect(()=> {
        if (precio === undefined) {
            setPrecio(precioMe)
        } 
    },[precioMe])

    const fijarPrecioBueno = () => {
        const valor1 = document.getElementById("select")
        const valor2 = valor1.value
        const valor3 = valor2
        if (valor3 === "pieza") {
        setPrecio(precioU)
       } 
       if (valor3 === "unidad" && cantidad < uvmayoreo) {
        setPrecio(precioMe)
       }
       if (valor3 === "unidad" && cantidad >= uvmayoreo) {
        setPrecio(precioMa)
       }
    }

    useEffect(()=>{
        fijarPrecioBueno()
    },[cantidad])

   
    const total = cantidad * precio

    const [maduracion, setMad] = useState(10)

    const madf = () => {
        const valor1 = document.getElementById("mdata")
        const valor2 = valor1.value
        setMad(valor2)
    }

      const anadir = (id) => {
        const itemId = itemObj.id
        const newCart = [...cart].map(item => item.id) 
        let resultado = newCart.includes(itemId)
        if (resultado === true) {
            alert("El producto ya se encuentra en el carrito!")
        }
        if (precio === 0) {
            alert("Selecciona precio!!!")
        }
        if (precio && precio !== 0 && resultado === false) {
            addToCartD(product, id, cantidad, precio, total, maduracion)
        }
        navigate(-1)
    }
    
    return (
        <div>
            <div className="produc-detail">
                <div className="title-detail">
                    <div className="title-detail-cont">
                        <h1>{name}</h1>
                        <p>{catName + " > " + name} </p>
                    </div>
                </div>
                <div className="div-detail-cont">
                    <div className="div-detail-left">
                        <div className='name-detail'><h1>{name}</h1></div>
                        <div className='img-detail'><img src={imagen} width={400} height={380} alt=""/></div>
                    </div>
                    <div className="div-detail-rigth">
                        <div className="rigth-cont">
                            <div className="maduracion" id="maduracion">
                                <div>Nivel de maduración</div>
                                <div className="nivel-mad">
                                    <div>
                                        <p>verde</p>
                                    </div>
                                    <div className="mad-range">
                                        <input type="range" list="mdata" min="0" max="10" defaultValue={5} step={5} onChange={madf} id="mdata"/>
                                            <datalist>
                                                <option value="0"></option>
                                                <option value="5"></option>
                                                <option value="10"></option>
                                            </datalist>
                                    </div>
                                    <div>
                                        <p>maduro</p>
                                    </div>
                                </div>
                            </div>
                            <div className="select-precio">
                                <div className='precio-msn'>Precio por</div>
                                <select name="select" id="select" onChange={fijarPrecioBueno}>
                                    <option value="unidad">{quantity}</option>
                                    <option value="pieza" id="pieza">Pieza</option>
                                </select>
                                <div><p className="mayoreo-msn">A partir de {uvmayoreo} x {quantity} se tomara el precio al mayoreo ({precioMa} por {quantity})</p></div>
                                <div className='price-detail'>${total} mxn</div>
                            </div>
                            <div className="descripcion"></div>
                            <div className="orden">
                                <div className="quantity">
                                    <div className="cantidad">{cantidad}</div>
                                    <div className="update-quantity">
                                        <div onClick={()=> sumar(id)}><img src={up} width={20} height={10} alt="..."/></div>
                                        <div onClick={()=> restar(id)}><img src={down} width={20} height={10} alt="..."/></div>
                                    </div>
                                </div>
                                <button className="detail-add" onClick={()=> anadir(product, id, cantidad, precio, total, maduracion)}><img width={20} height={20} src={cartwhite}/><p>Añadir al carrito</p></button>
                            </div>
                        </div>
                    </div>  
                </div>  
            </div>
            <Footer/>
        </div>
    )
}

export default ProductDetails
import React, { useState, useContext, useEffect } from "react";
import AuthContext from "./AuthContext";


export default function Login () {

    //const { loginUser } = useContext(AuthContext)

    const [nombre, setNombre] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()


    const customUser = {
        'username': nombre,
        'email': email,
        'password': password
    }
    
    const submitCustomUser = () => {
        fetch('/blairfoodsb/user/create/', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(customUser),
        })
    }

   
    const submit = () => {
        submitCustomUser()
    }

    
    return (
        <div className="login">
            <div className="login-container">
                <div className="singin">
                    <p>Iniciar sesion</p>
                    {/* <form onSubmit={loginUser}> */}
                    <form>
                        <div className="login-data"><div className="login-label">Usuario</div><input type="text"  name="username" placeholder="Usuario"  /></div>
                        <div className="login-data"><div className="login-label">Contraseña</div><input type="password" name="password" placeholder="Contraseña" /></div>
                        <input type="submit" className="login-submit"/>
                    </form>
                </div>
                <br/>
                <br/>
                <div className="register">
                    <p>No tienes cuenta con nosotros? registrate</p>
                    <form onSubmit={submit}>
                        <div className="login-data"><div className="login-label">Nombre</div><input type="text" placeholder="Nombre de usuario" onChange={(e) => {setNombre(e.target.value)}} /></div>
                        <div className="login-data"><div className="login-label">Email</div><input type="text" placeholder="Email" onChange={(e) => {setEmail(e.target.value)}}/></div>
                        <div className="login-data"><div className="login-label">Contraseña</div><input type="password" placeholder="Contraseña"onChange={(e) => {setPassword(e.target.value)}}/></div>
                        <input type="submit" className="login-submit"/>
                    </form>
                </div>
            </div>
            
        </div>
    )
}
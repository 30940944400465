import React, { createContext, useState, useEffect } from "react";
import items from '../productos/productos.json'

export const ProductContext =  createContext()

const ProductProvider = ({children}) => {

    const [products, setProducts] = useState([])

    useEffect(() => {
        setProducts(items)
    },[])

    return ( <ProductContext.Provider value={{products}}>{children}</ProductContext.Provider> 
    )
}

export default ProductProvider
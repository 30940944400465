import mayoreo from './media/mayoreo.png';
import diverse from './media/diverse.png';
import call from './media/call.png';
import cupon from './media/cupon.png'

export default function Informacion() {
    return (
        <div className="info">
            <div className="one">
                <img src={cupon} className="info-img1" width={80} height={80} alt="..."/>
                <p>Los mejores precios</p>
            </div>
            <div className="two">
                <img src={mayoreo} className="info-img2" width={80} height={80} alt="..."/>
                <p>Si no encuentras lo que buscas, contactanos y lo conseguiremos por ti</p>
            </div>
            <div className="three">
                <img src={diverse}  className="info-img3" width={80} height={80} alt="..."/>
                <p>Productos de calidad y amplio surtido</p>
            </div>
            <div className="four">
                <img src={call} className="info-img4" width={80} height={80} alt="..."/>
                <p>Realiza tu pedido por correo o por telefono</p>
            </div>
        </div>
    )
}
import { CartContext } from "./CartContext";
import CartItem from "./cartitem";
import { useContext, useEffect, useId, useState } from 'react';
import AuthContext from "./AuthContext";
import { peticionFiscal } from "./fiscalrequest";
import axios from 'axios';
import down from './media/arrow-bar-down.svg';
import envios from '../productos/envios.json'

function Cart({close}) {


  // const { user } = useContext(AuthContext)

  // const [cliente, setCliente] = useState()

  // useEffect(()=>{
  //   if (user) {
  //     setCliente(user.user_id)
  //   }
  // },[user])


  const { cart, clearCart, total } = useContext(CartContext)

  const ordenId = useId()

  const getItems = async() => {
    let carro = cart;
    let i = carro.length
    for (let a = 0; a < i; a++) {
        const itemCart = cart[a]
        const  item =  {
          'ordenId': ordenId,
          'producto': itemCart.id,
          'maduracion': itemCart.maduracion,
          'precio': itemCart.precio,
          'cantidad': itemCart.amount
        }
          fetch('/blairfoodsb/item/create/', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item),
        })
    }
  }


  const [cp, setCp] = useState(null)

    const fijarCp = () => {
        const valor1 = document.getElementById("select-cp")
        const valor2 = valor1.value
        setCp(valor2)
    }

    const [envio, setEnvio] = useState(0)

    const fijarEnvio = () => {
        const costo = envios.find((item) => {
            return item.cp === parseInt(cp)
        })
    
        if (costo) {
            setEnvio(costo.monto) 
        }
    }
    
    useEffect(()=>{
        fijarEnvio()
    },[cp])

    const totalMasEnvio = total + envio

    const [pago, setPago] = useState(null)

    const fijarPago = () => {
      const valor1 = document.getElementById("select-ccc")
      const valor2 = valor1.value
      setPago(valor2)
  }

  useEffect(()=>{
    fijarPago()
  },[pago])

  const [factura, setFactura] = useState(false)

    const fijarFactura = () => {
      const checkbox = document.getElementById("cbox1")
      if(checkbox.checked) {
        setFactura(true)
      } else {
        setFactura(false)
      }
  }

  useEffect(()=>{
    fijarFactura()
  },[factura])

  

  const getOrder = () => {
    const orden = {
      'ordenId': ordenId,
      //'cliente': cliente,
      'total': total,
      'envio': envio,
      'pago': pago,
      'factura': factura
    }
      fetch('/blairfoodsb/orden/create/', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(orden),
        })

      //console.log(orden)
  }


  const handleSubmit = () => {
      // if (cliente === undefined) {
      //   alert("Inicie sesion para comprar!")
      // } if (cliente !== undefined) {
      //   getOrder()
      //   getItems()
      //   clearCart()

      // alert("Su orden se ha procesado con exito!")
      //    console.log(peticionFiscal)
      // }
      //fiscalRequest()
      console.log(envio, pago, factura)
    }


    const fiscalRequest = () => {
      axios.post('https://api.enlacefiscal.com/v6/probarConexion', {
        headers: { 
            'Content-Type': 'application/json',
            'x-api-key': 'e9aT1ajrRh1NyRkzOtDoN1ZEGmIsEKuJ6f3FYyLh',
            "Access-Control-Allow-Origin": "*",
          
        },
        user: {
            'BFO230412NS9':'6de009d69dcb70cc6558ae8f05db5266'
        },
        Solicitud: {
          "rfc": "BFO230412NS9",
          "accion": "probarConexion"
      } 
    })
    .then(res=>{console.log(res)})
    }


    return (
      <div className='carrito'>
        <div className="cart">
          <div className="title-cart">Mi Carrito</div>
          <div className='title-table'>
            <div className='title-t-one' onClick={clearCart}>Limpiar</div>
            <div className='title-t-two'></div>
            <div className='title-t-three'>Producto</div>
            <div className='title-t-four'>Precio</div>
            <div className='title-t-five'>Cantidad</div>
            <div className='title-t-six'>Subtotal</div>
          </div>
          <div className="bag-items">
            {cart.map(item => {
              return <CartItem item={item} key={item.id}/>
            })}
          </div>
          <div className='total-cart'>
            <div className='total-table'>
              <div className='total-t-zero'>Totales del carrito</div>
              <div className='total-t-one'>Subtotal:</div>
              <div className='total-t-two'>${total}</div>
              <div className='total-t-three'>Envio:</div>
              <div className='total-t-four'>
                <div className="envio">
                  <select name="select-cp" id="select-cp" onChange={fijarCp}>
                    <option value="0">Codigo postal</option>
                    <option value="76000">76000</option>
                    <option value="76025">76025</option>
                    <option value="76040">76040</option>
                    <option value="76100">76100</option>
                    <option value="76110">76110</option>
                  </select>
                  <div className='monto-envio'> ${envio}</div>
                </div>
              </div>
              <div className='total-t-five'>Total:</div>
              <div className='total-t-six'>${totalMasEnvio}</div>
              <div className='total-t-seven'>
                <button className='comprar' onClick={handleSubmit}>Finalizar compra</button>
              </div>

              <div className='total-t-eighth'>
                <div>Método de pago: </div>
              </div>
              <div className='total-t-nine'>
                <div className="modo-pago">
                    <select name="select-ccc" id="select-ccc" onChange={fijarPago}>
                      <option value="Efectivo">Efectivo</option>
                      <option value="Tarjeta">Tarjeta</option>
                    </select>
                  </div>
              </div>
              <div className='total-t-ten'>
                <input type="checkbox" id="cbox1"  className="checkbox" value="si" onChange={fijarFactura}/>
                <label for="cbox1">Factura</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Cart;
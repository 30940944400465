
import { Link } from "react-router-dom";
import menufrutas from './media/MENU_Frutas-scaled.jpg';
import menuverduras from './media/MENU_Verduras-2-scaled.jpg';
import menuchiles from './media/MENU_Secos-scaled.jpg';
import menufrutossecos from './media/MENU_Frutos-secos-y-semillas-scaled.jpg';
import menuespecias from './media/MENU_Hierbas-y-especias-scaled.jpg';
import menucereales from './media/MENU_Cereales-y-leguminosas-3.1-scaled.jpg';

export default function Menu2() {

    return (
        <div className="menu2">
            <Link className="m2-item" to="/verduras">
                <div className="m2">
                    <img className="m2-image" width={180} height={100} src={menuverduras} alt=""/>
                    <div className="m2-title">Verduras</div>
                </div>
            </Link>
            <Link className="m2-item" to="/frutas">
                <div className="m2">
                    <img className="m2-image" width={180} height={100} src={menufrutas} alt=""/>
                    <div className="m2-title">Frutas</div>
                </div>
            </Link>
            <Link className="m2-item" to="/chilessecos">
                <div className="m2">
                    <img className="m2-image" width={180} height={100} src={menuchiles} alt=""/>
                     <div className="m2-title">Chiles secos</div>
                </div>
            </Link>
            <Link className="m2-item" to="/frutossecosysemillas">
                <div className="m2">
                    <img className="m2-image" width={180} height={100} src={menufrutossecos} alt=""/>
                    <div className="m2-title">Frutos secos y semillas</div>
                </div>
            </Link>
            <Link className="m2-item" to="/especias">
                <div className="m2">
                    <img className="m2-image" width={180} height={100} src={menuespecias} alt=""/>
                    <div className="m2-title">Especias</div>
                </div>
            </Link>
            <Link className="m2-item" to="/cerealesyleguminosas">
                <div className="m2">
                    <img className="m2-image" width={180} height={100} src={menucereales} alt=""/>
                    <div className="m2-title">Cereales y leguminosas</div>
                </div>
            </Link>
        </div>
    )
}


import React from 'react';
import { Link } from 'react-router-dom';
import cartwhite from './media/cartwhite.png';

const FindedItem = ({product}) => {
    
    const { id, imagen, nombre } = product
 
    return (
        <div className='finded-item'>
            <Link  className="finded-item-link" to={`/detail/${id}`}>
                <img  width={50} height={50} src={imagen} alt=""/>
                <div className='finded-item-name'>{nombre}</div>
            </Link>
        </div> 
    )   
}
  
  export default FindedItem;

import React, { useContext } from "react";
import { CartContext } from "./CartContext";
import up from './media/up.png';
import down from './media/down.png';
import xcircle from './media/xcircle.svg'

const CartItem = ({item}) => {

    const { removeFromCart, restOne, addOne } = useContext(CartContext)

    const { id, nombre, amount, imagen, precio } = item

    return (
        <div className="cart-item">
            <div className="i-one">
                <div className="cancel-item"><img src={xcircle} width={20} height={20} alt="..." onClick={()=> removeFromCart(id)}/></div>
            </div>
            <div className="i-two">
                <img className="ci-image"  width={50} height={50} src={imagen} alt=""/>
            </div>
            <div className="i-three">
                <div className="ci-name">{nombre}</div>
            </div>
            <div className="i-four">{precio}</div>
            <div className="i-five">
                <div className="quantity-cart">
                    <div className="cantidad-cart">{amount}</div>
                    <div className="update-quantity">
                        {/* <div onClick={()=> addOne(id)}><img src={up} width={10} height={5} alt="..."/></div>
                        <div onClick={()=> restOne(id)}><img src={down} width={10} height={5} alt="..."/></div> */}
                    </div>
                </div>
            </div>
            <div className="i-six">
                <div className="ci-price">${amount * precio}</div>
            </div>
        </div>
    )
}

export default CartItem;
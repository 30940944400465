import { Link } from "react-router-dom";
import logo from './media/fulllogo.png';

export default function Footer() {
    return (
        <div className="footer">
            <Link to="/" className="logo"><img src={logo} width={200} height={80} alt="..."/></Link>
            <p>© Blair 2023. Todos los derechos reservados.</p>
            <p>Política de Privacidad | Términos y Condiciones</p>
        </div>
    )
}

import mision from './media/mision.png';

export default function Mision() {
    return (
            <div className="mision-portrait">     
                <div className='title-mision'>
                    <h3>Misión</h3>
                    <p>En Blair tenemos una misión muy clara que guía nuestros pasos:</p>
                    <img src={mision} className="mis-img" width={50} height={50} alt="..." />
                <p>Ofrecer alimentos de la mejor calidad, impulsando el desarrollo de la sociedad y garantizando la protección del medio ambiente.</p>
            </div>
                
        </div>
    )
}

import principios1 from './media/principios_1.png';
import principios2 from './media/principios_2.png';
import principios3 from './media/principios_3.png';
import principios4 from './media/principios_4.png';
import principios5 from './media/principios_5.png';
import principios6 from './media/principios_6.png';
import principios7 from './media/principios_7.png';
import principios8 from './media/principios_8.png';
import Footer from './footer';

export default function Principios() {
    return (
        <div>
            <div className="principios">
                <div className="title">
                    <div>
                        <h3>Principios</h3>
                        <p>Dentro de Blair nuestro actuar es guiado por 8 principios:</p>
                    </div>
                </div>
                <div class="wrapper">
                    <div class="one">
                        <img src={principios6} className="w-img" width={50} height={50} alt="..."/>
                        <h5>El cliente es nuestro motivo</h5>
                        <p>Trabajamos cada día para ganarnos la confianza de nuestros clientes y cumplir totalmente sus expectativas. Nuestro camino inicia con el cliente y termina con él.</p>
                    </div>
                    <div class="two">
                        <img src={principios2} className="w-img" width={50} height={50} alt="..."/>
                        <h5>Obsesión por la perfección</h5>
                        <p>En Blair tenemos un ímpetu insaciable por la perfección. Nunca la lograremos pero siempre iremos tras ella. Subir los estándares es parte de nuestro ADN.</p>
                    </div>
                    <div class="three">
                        <img src={principios3} className="w-img" width={50} height={50} alt="..."/>
                        <h5>El mayor activo de la fortaleza es y será sus miembros</h5>
                        <p>Blair es una comunidad de personas talentosas y comprometidas. Una familia que cuida a cada uno de sus integrantes.</p>
                    </div>
                    <div class="four">
                        <img src={principios4} className="w-img" width={50} height={50} alt="..."/>
                        <h5>Visión hacia el futuro sin perder el presente</h5>
                        <p>Soñamos con un mañana mejor pero nunca olvidamos que vivimos en el hoy. Nuestras metas se cumplen paso a paso de acuerdo a una estrategia.</p>
                    </div>
                    <div class="five">
                        <img src={principios5} className="w-img" width={50} height={50} alt="..."/>
                        <h5>Nuestros sueños nunca serán demasiado grandes</h5>
                        <p>Nunca una meta será demasiado ambiciosa, entendemos que para ganar hay que arriesgar, por lo que no tenemos miedo al fracaso.</p>
                    </div>
                    <div class="six">
                        <img src={principios1} className="w-img" width={50} height={50} alt="..."/>
                        <h5>Nuestras acciones siempre hablarán por nosotros</h5>
                        <p>El profesionalismo y la ética no es algo que se cuenta, sino que se ve. Poner el ejemplo será siempre la forma más efectiva de cambiar nuestro entorno.</p>
                    </div>
                    <div class="seven">
                        <img src={principios7} className="w-img" width={50} height={50} alt="..."/>
                        <h5>El fin nunca justifica los medios</h5>
                        <p>El impacto de nuestras acciones siempre será un criterio de decisión. El desarrollo del medio ambiente y nuestra sociedad serán una prioridad en cada paso.
                        </p>
                    </div>
                    <div class="eight">
                        <img src={principios8} className="w-img" width={50} height={50} alt="..."/>
                        <h5>Los problemas del mundo son nuestros problemas</h5>
                        <p>Blair es una comunidad de líderes y como tal, tomamos propiedad de los problemas del mundo, por lo que solucionarlos desde nuestra trinchera es parte de nuestra cultura.</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
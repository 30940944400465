
import { Link } from "react-router-dom"

export default function Navbar() {

    return (
        <div className="navbar">
            <div className="btn-group dropend">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="btn-categorias">
                    Categorias
                </button>
                <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="/verduras">Verduras</Link></li>
                    <li><Link className="dropdown-item" to="/frutas">Frutas</Link></li>
                    <li><Link className="dropdown-item" to="/chilessecos">Chiles secos</Link></li>
                    <li><Link className="dropdown-item" to="/frutossecosysemillas">Frutos secos y semillas</Link></li>
                    <li><Link className="dropdown-item" to="/especias">Especias</Link></li>
                    <li><Link className="dropdown-item" to="/cerealesyleguminosas">Cereales y leguminosas</Link></li>
                </ul>
            </div>
            <div className="navbar-div">
                <div className="dropdown-center">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Nosotros
                    </button>
                    <ul className="dropdown-menu">
                        <li><Link className="dropdown-item" to="/myv">Mision y vision</Link></li>
                        <li><Link className="dropdown-item" to="/principios">Principios</Link></li>
                    </ul>
                </div>
                <div className="dropdown-center">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Queremos conocerte
                    </button>
                    <ul className="dropdown-menu">
                        <li><Link className="dropdown-item" to="/proveedores">Proveedores</Link></li>
                        <li><Link className="dropdown-item" to="/clientes">Clientes</Link></li>
                    </ul>
                </div>
                <Link to="/contacto" className="navbar-link">Contacto</Link>
            </div>       
        </div>
    )
}
import { useEffect } from 'react';
import Footer from "./footer";
import Informacion from "./info";
import home from './media/home.jpg';
import HotPics from "./hotpics";
import Menu2 from './menu2';


export default function Hero() {
    
    return (
        <div className="hero">
            <div className="portrait">
                <img className="img-home" src={home} alt=""/>
                <div className="title">
                    <h3 className="main-title">Bienvenidos</h3>
                    <p className="main-msn">Te damos la bienvenida, Blair es una compañia cuyo objetivo es poner a tu alcance la verdadera riqueza del campo mexicano en sus mas altos estandares</p>
                </div>
            </div>
            <Menu2/>
            <HotPics />
            <Informacion/>
            <Footer/>
        </div>
    )
}

import React, { useEffect, useState, useContext } from 'react';
import { ProductContext } from './ProductContext';
import Product from './product';
import ar from './media/ar.png';

export default function Frutas() {

  const { products } = useContext(ProductContext)
  const frutas = products.filter((product)=> product.categoria === 1)


    return (
      <div className="shop">
        <div className='shop-title'>
          <div className="title-detail-cont">
            <h1>Frutas</h1>
            <p className="detail-ruta">Categorias <img src={ar} width={15} height={15} alt=""/>Frutas</p>
          </div>
        </div>
        <div className="shop-container">
          <div className="product-container">
          {frutas.map((product) => {
                return (
                    <Product product={product} key={product.id}/>
                ) 
            })} 
          </div>
        </div>
      </div>
    );
}
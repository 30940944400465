

export default function Nosotros() {
    return (
        <div className="nosotros-portrait">
            <div className='title-nosotros'>
                <h3>Nosotros</h3>
                <p>Blair es una compañía 100% mexicana integrada por un grupo talentoso de personas que tiene como propósito llevar hasta tu mesa alimentos de la mejor calidad. Conoce más sobre nosotros…</p>
            </div>
        </div>
    )
}
import { Link } from "react-router-dom";

export default function Slidebar2() {

    return (
        <div className="slide-menu2" >
            <Link to="/frutas" className="ss-link">Frutas</Link>
            <Link to="/verduras" className="ss-link">Verduras</Link>
            <Link to="/chilessecos" className="ss-link">Chiles secos</Link>
            <Link to="/frutossecosysemillas" className="ss-link">Frutos secos y semillas</Link>
            <Link to="/especias" className="ss-link">Especias</Link>
            <Link to="/cerealesyleguminosas" className="ss-link">Cereales y leguminosas</Link>
        </div>
    )
}
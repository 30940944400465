import React, { useState, useContext, useEffect, useRef } from 'react';
import { ProductContext } from './ProductContext';
import FindedItem from './finded-item';
import lupa from './media/searchwhite.png';


export default function Search() {

    const Ref = useRef(null);
    
    const handleOutsideClick = (e) => {
      if (
        Ref.current &&
        !Ref.current.contains(e.target) 
      ) {
        setArticulo([])
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, []);

    const { products } = useContext(ProductContext)

    const [itemS, setItemS] = useState(null)

    // useEffect(()=> {
    //     setItemS()
    // },[itemS])

    const handleChange = (value) => {
        setItemS(item => ({ ...item, 'nombre': value }))
    }

    const [articulo, setArticulo] = useState([])
    
    const [showSearch, setShowSearch] = useState(false)

    const buscar = () => {
        // const product = products.map((e)=>{
        //     return e.nombre})

        const product1 = products.filter(i => {
            const value1 = i.nombre.toLowerCase()
            const value2 = itemS.nombre.toLowerCase()
            return (value1.includes(value2))
        })
        
        if (product1.length === 0) {
            alert('No hay resultados para su busqueda!')
        } else {
            setArticulo(product1)
            setShowSearch(true)
        }
    }

    useEffect(()=> {
        if (articulo.length === 0) {
            setShowSearch(false)
        }
    },[articulo])

    
    const clear = () => {
        setArticulo([])
    }


    return (
        <div className="search" ref={Ref}>
            <div className='finder'>
                <input type='text' placeholder='Busca tu producto favorito' onChange={(e) => { handleChange(e.target.value) }} value={itemS?.nombre}></input>
                <button onClick={buscar}><img src={lupa} width={25} height={25} alt="..."/></button>
            </div>
           <div className="finder-container" onClick={clear}>
            {articulo.map((product) => {
                return (
                    <FindedItem product={product} key={product.id}/>
                ) 
            })} 
          </div>
          
        </div>
    )
}
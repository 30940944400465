
import React, { useContext } from 'react';
import { ProductContext } from './ProductContext';
import Product from './product';
import ar from './media/ar.png';

export default function Cereales() {

  const { products } = useContext(ProductContext)
  const cereales = products.filter((product)=> product.categoria === 6)

    return (
      <div className="shop">
        <div className='shop-title'>
          <div className="title-detail-cont">
            <h1>Cereales y leguminosas</h1>
            <p className="detail-ruta">Categorias <img src={ar} width={15} height={15} alt=""/>Cereales y leguminosas</p>
          </div>
        </div>
        <div className="shop-container">
          <div className="product-container">
          {cereales.map((product) => {
                return (
                    <Product product={product} key={product.id}/>
                ) 
            })} 
          </div>
        </div>
      </div>
    );
}